
import { useServiceCatalog } from '@/services/useServiceCatalog'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import Editor from '@/pages/admin/catalog/components/Editor.vue'
import DesignTemplate from './DesignTemplate.vue'
import Variants from './Variant.vue'
import UploadImage from '@/components/common/UploadImage.vue'
import { useRoute, useRouter } from 'vue-router'
import { reactive, defineComponent, ref, computed } from 'vue'
import { Catalog, Variant } from '@/interfaces/catalog'
import userCatalogRepositories from '@/repositories/userCatalogRepositories'
import useCatalogVariantRepositories from '@/repositories/useCatalogVariantRepositories'
import { useFetchData, useNotification } from '@/composables'
import useBaseCostReponsitories from '@/repositories/useBaseCostReponsitories'

export default defineComponent({
  components: {
    sectionLayoutContent,

    DesignTemplate,
    UploadImage,
    Variants,
  },
  name: 'Edit Catalog',
  setup() {
    const breadcrumbs = ['Catalog', 'Edit Catalog']
    const content = computed(() => {
      return { breadcrumbs }
    })
    const costBy = ref('size')
    const catalog = reactive<any>({
      _id: '',
      name: '',
      categories: '',
      productCode: '',
      description: '',
      variants: [],
      sizes: [],
      colors: [],
      shipping: '',
      production: '',
      mockupGallery: [],
      designs: [],
    })
    console.log('call back 2')

    const isLoading = ref(true)
    const route = useRoute()
    const router = useRouter()
    const change = ref<boolean>(false)
    const { error, success } = useNotification()
    const getData = async () => {
      isLoading.value = true
      const { getBaseCost } = useBaseCostReponsitories()
      const { getCatalog } = userCatalogRepositories()
      const { status, data } = await getCatalog(route.params.id)
      data.categories = data.categories.map((item: any) => item._id)
      Object.assign(catalog, data)

      let res = await getBaseCost(route.params.basecostId as string)

      if (res && res.variantPriceMapping) {
        catalog.variants.forEach((s: any) => {
          if (res.variantPriceMapping[s._id] !== null) {
            s.cost = res.variantPriceMapping[s._id]
          }
        })
      }
      isLoading.value = false
    }

    const baseCostId = computed(() => {
      return route.params.basecostId
    })

    const variantsTemp = ref<any[]>([])
    getData()
    const changeDescription = (html: string, dataSet: any) => {
      dataSet.description = html
    }
    const changeDescription2 = (html: string, dataSet: any) => {
      dataSet.description2 = html
    }
    const params = reactive({})
    const { documents, fetchData } = useFetchData(`/catalog-categories`, params)
    fetchData()
    const addDesign = (data: any, dataSet: any, status: any) => {
      if (status.index > -1) {
        dataSet.splice(status.index, 1, data)
      } else {
        dataSet.push(data)
      }
    }
    const callbackMockup = (setData: any, files: string[]) => {
      setData.mockupGallery = files
    }
    const changeProduction = (html: string, dataSet: any) => {
      dataSet.production = html
    }
    const changeShipping = (html: string, dataSet: any) => {
      dataSet.shipping = html
    }
    const changeMaterial = (html: string, dataSet: any) => {
      dataSet.material = html
    }
    const changeCostBy = (value: string) => {
      costBy.value = value
    }
    const delDesign = (index: number | 0) => {
      catalog?.designs?.splice(index, 1)
    }
    const _idVariants = ref<any[]>([])
    const callbackVariant = (
      colors: any[],
      sizes: any[],
      variants: Variant[],
      t: string,
      _id: any[]
    ) => {
      catalog.colors = colors
      catalog.sizes = sizes
      if (t === 'edit') {
        change.value = true
      }
      catalog.variants = catalog.variants.concat(variants)
      _idVariants.value = _id
      variantsTemp.value = [...variants]
    }
    const rulesCatalog = {
      name: [
        {
          required: true,
          message: 'Please input name',
          trigger: 'blur',
        },
      ],
      categories: [
        {
          required: true,
          message: 'Please input category',
          trigger: 'blur',
        },
      ],
      productCode: [
        {
          required: true,
          message: 'Please input product code',
          trigger: 'blur',
        },
      ],
    }
    const isSubmit = ref<boolean>(false)
    const catalogFormUpdate = ref<any>(null)
    const updateCatalog = async () => {
      isSubmit.value = true
      catalogFormUpdate.value?.validate(async (valid: any) => {
        if (valid) {
          const { update } = userCatalogRepositories()
          const { updateVariant, deleteByIdVariant, addVariant } =
            useCatalogVariantRepositories()
          let noIdVariant = catalog.variants?.filter(
            (i: any) => !i._id || i._id === ''
          )
          console.log('noIdVariant', noIdVariant)
          const statusVariant = ref<boolean>(true)
          if (noIdVariant && noIdVariant.length > 0) {
            noIdVariant.forEach(async (i: any) => {
              const { status, data } = await addVariant(
                Object.assign(i, {
                  catalog: catalog._id,
                })
              )
              if (status !== 201) {
                statusVariant.value = false
              }
            })
          }
          if (_idVariants.value.length > 0) {
            _idVariants.value.forEach(async (v: any) => {
              if (v.action === 'edit') {
                if (catalog.variants?.find((i: any) => i._id === v._id)) {
                  const { status, data } = await updateVariant(
                    Object.assign(
                      catalog.variants.find((i: any) => i._id === v._id),
                      {
                        catalog: catalog._id,
                      }
                    )
                  )
                  if (status !== 200) {
                    statusVariant.value = false
                  }
                }
              } else if (v.action === 'delete') {
                const { status, data } = await deleteByIdVariant(v._id)
                if (status !== 200) {
                  statusVariant.value = false
                }
              }
            })
          }
          console.log('noIdVariant', noIdVariant)
          delete catalog.variants
          const { status, data } = await update(catalog)
          if (status === 200 && statusVariant.value) {
            success('Update Catalog Success!')
            router.push({
              name: 'ag.base-cost.catalog-update-cost',
              params: {
                id: route.params.id,
                basecost: route.params.basecostId,
              },
            })
            isSubmit.value = false
          }
        }
      })
    }
    const dialog = ref<boolean>(false)
    const cancelDelete = () => {
      dialog.value = false
    }
    const deleteCatalog = () => {
      dialog.value = true
    }
    const confirmDelete = async () => {
      const { deleteById } = userCatalogRepositories()
      const { status, data } = await deleteById(catalog)
      if (status === 200) {
        router.push({ name: 'a.catalogs' })
      }
      console.log('status', status)
    }
    const discard = () => {
      window.location.reload()
    }
    return {
      getData,
      route,
      router,
      content,
      catalog,
      costBy,
      changeDescription,
      changeDescription2,
      changeMaterial,
      addDesign,
      callbackMockup,
      changeProduction,
      changeShipping,
      changeCostBy,
      delDesign,
      useServiceCatalog,
      isLoading,
      callbackVariant,
      updateCatalog,
      change,
      variantsTemp,
      _idVariants,
      dialog,
      cancelDelete,
      deleteCatalog,
      confirmDelete,
      discard,
      isSubmit,
      catalogFormUpdate,
      rulesCatalog,
      documents,
      baseCostId,
    }
  },
})
