
import { computed, defineComponent, reactive, ref, watch } from 'vue'
import { stringLimit } from '@/utils/string'
// import { Edit, Delete, CloseBold } from '@element-plus/icons'
// import ColorForm from './ColorForm.vue'
// import SizeForm from './SizeForm.vue'
import { ElMessageBox } from 'element-plus'
import useCatalogVariantRepositories from '@/repositories/useCatalogVariantRepositories'
import { useNotification } from '@/composables'
// import EditVariantForm from './EditVariantForm.vue'
import { Mixed, mixer } from '@/utils/variants'
import BulkUpdate from './BulkUpdate.vue'
import { useRouter } from 'vue-router'
import AddSetCost from './AddSetCost.vue'
import { formatterDollars } from '@/utils/formatter'

import { useRoute } from 'vue-router'
import { sortVariant } from '@/utils/catalog'

export interface Attribute {
  name: string
  code: string
  value: string
  cost: string
}
export default defineComponent({
  components: {
    // ColorForm,
    // SizeForm,
    // EditVariantForm,
    BulkUpdate,
    AddSetCost,
  },
  props: {
    dataSet: {
      type: Object,
    },
    defaultCostBy: {
      type: String,
      default: 'size',
    },
    colors: {
      type: Array,
      default: () => [],
    },
    sizes: {
      type: Array,
      default: () => [],
    },
    values: {
      type: Array,
      default: () => [],
    },
    productCode: {
      type: String,
    },
    baseCost: {
      type: String,
    },
    typeAction: {
      type: String,
      default: 'create',
    },
  },
  setup(props, { emit }) {
    const { deleteByIdVariant } = useCatalogVariantRepositories()
    const costBy = ref<string | undefined>(props.defaultCostBy)
    const colorsValue = ref<any[]>([...props.colors])
    const sizesValue = ref<any[]>([...props.sizes])
    const variants = ref<any[]>([...props.values])
    const addVariants = ref<any[]>([])
    const typeInAction = ref<any[]>([...props.typeAction])

    // const updateVariants = ref<any[]>([])
    const deleteVariantIds = ref<string[]>([])
    const dialog = ref(false)
    const options = ref([
      {
        title: 'Cost by size',
        value: 'size',
      },
      {
        title: 'Cost by color',
        value: 'color',
      },
    ])
    const { params } = useRoute()

    const changeCostBy = () => {
      emit('changeCostBy', costBy.value)
    }

    const filterSizes = ref<string[]>([])
    const filterColors = ref<string[]>([])

    const onSelectFilter = () => {
      const seletedSizes: string[] = Array.from(filterSizes.value)
      const seletedColors: string[] = Array.from(filterColors.value)
      let allVariants: any[] = [...props.values]
      if (seletedSizes?.length > 0 || seletedColors?.length > 0) {
        if (seletedSizes?.length > 0) {
          allVariants = allVariants.filter(variant =>
            seletedSizes.includes(variant.size?.name)
          )
        }
        if (seletedColors?.length > 0) {
          allVariants = allVariants.filter(variant =>
            seletedColors.includes(variant.color?.name)
          )
        }
      }
      variants.value = allVariants
    }

    const router = useRouter()
    const bulkUpdateCost = () => {
      console.log('callback', {
        params: { id: params.id, basecostId: props.baseCost },
      })
      router.push({
        name: 'ag.base-cost.catalog-update-cost',
        params: { id: params.id, basecostId: props.baseCost },
      })
      console.log(params.id, props.baseCost)
    }

    const selectedIds = computed(() => {
      console.log(
        'multipleSelection',
        multipleSelection.value.map((variant: any) => variant._id)
      )
      return multipleSelection.value.map((variant: any) => variant._id)
    })

    const baseCostId = computed(() => {
      return props.baseCost
    })

    const catalogId = computed(() => {
      return params.id
    })

    const catalogAttribute = ref<any>(null)
    const catalogVariant = ref<any>(null)
    const addAttribute = (type: string, attr: Attribute) => {
      // Add new attribute value to target product attribute
      if (type === 'color') {
        colorsValue.value.push(attr)
      } else {
        sizesValue.value.push(attr)
      }
      if (colorsValue.value.length && sizesValue.value.length) {
        if (props.typeAction === 'create') {
          createNewAttribute()
        } else {
          addValueToExistedAttr(type, attr)
        }
      }
    }

    // Trường hợp này xử lý khi thêm mới toàn bộ attribute
    const createNewAttribute = () => {
      const mixed: Mixed = { size: sizesValue.value, color: colorsValue.value }
      mix(mixed)
    }

    const sliceVariant = (index: number) => {
      variants.value?.splice(index, 1)
    }

    // Trường hợp này thì mix lại thêm variant rồi gửi bulk insert variants lên
    const addValueToExistedAttr = (type: string, value: Attribute) => {
      let mixed: Mixed = { size: [], color: [] }
      if (type === 'size') {
        mixed = { size: [value], color: colorsValue.value }
      } else {
        mixed = { color: [value], size: sizesValue.value }
      }
      mix(mixed)
    }

    const mix = (mixed: Mixed) => {
      // Mix variants
      let newVariants = mixer(mixed, {
        inStock: true,
        description: '',
        costBy: costBy.value,
        productCode: props.productCode,
        catalog: params.id,
      })

      // preview
      if (props.typeAction === 'create') {
        variants.value = newVariants
      } else {
        variants.value = variants.value.concat(newVariants)
      }
      addVariants.value = newVariants
    }

    // Xóa 1 attribute thì xóa toàn bộ variants có attribute đó trong product
    const deleteAttribute = async (target: string, code: string) => {
      if (target === 'size') {
        let index = sizesValue.value.findIndex(size => size.code === code)
        if (index > -1) {
          console.log('delete size', index, code)
          sizesValue.value.splice(index, 1)
        }
        const ids = []
        for (const variant of variants.value) {
          if (variant?.size?.code && variant.size.code === code) {
            ids.push(variant?._id || variant?.catalogVariantCode)
          }
        }
        deleteVariantIds.value = deleteVariantIds.value.concat(ids)
      } else {
        let index = colorsValue.value.findIndex(color => color.name === code)
        if (index > -1) {
          console.log('delete color', index, code)
          colorsValue.value.splice(index, 1)
        }
        const ids = []
        for (const variant of variants.value) {
          if (variant?.color?.name && variant.color.name === code) {
            ids.push(variant?._id || variant?.catalogVariantCode)
          }
        }
        deleteVariantIds.value = deleteVariantIds.value.concat(ids)
      }
      previewDeleteVariants() // xóa tạm variant đi để preview trước khi save
    }

    const previewDeleteVariants = () => {
      for (let index = 0; index < deleteVariantIds.value.length; index++) {
        let variantIndex = variants.value.findIndex(
          variant =>
            variant._id === deleteVariantIds.value[index] ||
            variant.catalogVariantCode === deleteVariantIds.value[index]
        )
        if (variantIndex > -1 && deleteVariantIds.value[index]) {
          variants.value.splice(variantIndex, 1)
        }
      }
      emit(
        'callback',
        colorsValue,
        sizesValue,
        addVariants.value,
        deleteVariantIds.value
      )
    }

    const sortCatalogVariants = () => {
      variants.value = sortVariant(variants.value)
    }
    sortCatalogVariants()

    watch(variants, () => {
      sortCatalogVariants()
      emit(
        'callback',
        colorsValue,
        sizesValue,
        addVariants.value,
        deleteVariantIds.value
      )
    })

    // const editVariantDialog = ref<InstanceType<typeof EditVariantForm>>()
    // const openEditVariantDialog = (id: string) => {
    //   editVariantDialog.value?.toggle(id)
    // }

    const { error, success } = useNotification()
    const deleteVariant = (id: string) => {
      ElMessageBox.confirm('Are you sure you want to delete variant?')
        .then(async () => {
          const { status, data } = await deleteByIdVariant(id)
          if (status === 200) {
            window.location.reload()
            success('Deleted')
          } else {
            error(data.message)
          }
        })
        .catch(err => {
          console.log(err)
        })
    }

    let multipleSelection = ref([])
    const handleSelectionChange = (val: []) => {
      multipleSelection.value = val
    }
    const addSetCostDialog = ref<InstanceType<typeof AddSetCost>>()
    const onSelectedAction = (action: string) => {
      addSetCostDialog.value?.toggle(action)
    }

    return {
      dialog,
      options,
      // sizeDialog,
      // colorDialog,
      costBy,
      sizesValue,
      colorsValue,
      typeInAction,
      variants,
      catalogAttribute,
      // editVariantDialog,
      catalogVariant,
      multipleSelection,
      addSetCostDialog,
      selectedIds,
      baseCostId,
      bulkUpdateCost,
      changeCostBy,
      addAttribute,
      deleteVariant,
      deleteAttribute,
      // openEditVariantDialog,
      stringLimit,
      addValueToExistedAttr,
      sliceVariant,
      handleSelectionChange,
      onSelectedAction,
      formatterDollars,
      catalogId,
      filterSizes,
      onSelectFilter,
      sortVariant,
      sortCatalogVariants,
      filterColors,
    }
  },
})
