
import { useNotification } from '@/composables'
import useCatalogVariantRepositories from '@/repositories/useCatalogVariantRepositories'
import { defineComponent, reactive, ref } from 'vue'

export default defineComponent({
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
    baseCost: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      action: 'add',
      unit: 'usd',
      amount: 0,
      title: '',
      actionType: '',
      actionTypeName: '',
    })
    const { bulkUpdate } = useCatalogVariantRepositories()
    const { error, success } = useNotification()
    let actionType = ref('')
    let dialogVisible = ref(false)
    const toggle = (atype: string) => {
      dialogVisible.value = !dialogVisible.value
      actionType.value = atype
      state.actionTypeName = atype
      switch (atype) {
        case 'showAddSet':
          state.title = `Cost`
          state.actionType = 'UPDATE_COST'
          break
        case 'showAddSetUsFirst':
          state.title = 'Fee Ship US First'
          state.actionType = 'UPDATE_SHIP'
          break
        case 'showAddSetUsAdd':
          state.title = 'Fee Ship US Additional'
          state.actionType = 'UPDATE_SHIP'
          break
        case 'showAddSetRowFirst':
          state.title = 'Fee Ship ROW First'
          state.actionType = 'UPDATE_SHIP'
          break
        case 'showAddSetRowAdd':
          state.title = 'Fee Ship ROW Addtional'
          state.actionType = 'UPDATE_SHIP'
          break
      }
    }

    const submit = async () => {
      console.log('basecost', props.baseCost)

      const patchData = {
        showAddSet: {
          ids: props.selected,
          basecost: props.baseCost,
          actionType: state.actionType,
          data: {
            action: state.action,
            amount: parseFloat(state.amount.toString()),
            unit: state.unit,
          },
        },
        showAddSetUsFirst: {
          ids: props.selected,
          basecost: props.baseCost,
          actionType: state.actionType,
          data: {
            shipUsFirst: parseFloat(state.amount.toString()),
          },
        },
        showAddSetUsAdd: {
          ids: props.selected,
          basecost: props.baseCost,
          actionType: state.actionType,
          data: {
            shipUsAdditional: parseFloat(state.amount.toString()),
          },
        },
        showAddSetRowFirst: {
          ids: props.selected,
          basecost: props.baseCost,
          actionType: state.actionType,
          data: {
            shipRowFirst: parseFloat(state.amount.toString()),
          },
        },
        showAddSetRowAdd: {
          ids: props.selected,
          basecost: props.baseCost,
          actionType: state.actionType,
          data: {
            shipRowAdditional: parseFloat(state.amount.toString()),
          },
        },
      }
      let patchUpdate = {}
      switch (state.actionTypeName) {
        case 'showAddSet':
          patchUpdate = patchData.showAddSet
          break
        case 'showAddSetUsFirst':
          patchUpdate = patchData.showAddSetUsFirst
          break
        case 'showAddSetUsAdd':
          patchUpdate = patchData.showAddSetUsAdd
          break
        case 'showAddSetRowFirst':
          patchUpdate = patchData.showAddSetRowFirst
          break
        case 'showAddSetRowAdd':
          patchUpdate = patchData.showAddSetRowAdd
          break
      }
      console.log('patch data', patchData)
      const { data, status } = await bulkUpdate(patchUpdate)
      if (status === 200) {
        close()
        success('Updated')
        window.location.reload()
      } else {
        error(data.message)
      }
    }

    const close = () => {
      dialogVisible.value = false
      state.amount = 0
    }

    return {
      state,
      actionType,
      dialogVisible,
      toggle,
      submit,
      close,
    }
  },
})
