export type Mixed = {
  size: Attribute | unknown[]
  color: Attribute | unknown[]
}

export type Attribute = {
  name: string
  code?: string
  value?: string
  cost?: number
}

/**
 * @returns variants mixed from product attributes
 * @param attributes product attributes
 * @param obj additional data for variant
 */
export const mixer = (attributes: Mixed, obj: any) => {
  let variants = []

  for (let [attr, values] of Object.entries(attributes)) {
    if (!Array.isArray(values) || !values.length) {
      values = [
        { [attr]: 'default', name: attr, code: ``, value: `${attr}-default` },
      ]
    }
    attr = attr as any
    values = values as Attribute[]
    variants.push(values.map(v => ({ [attr]: v })))
  }

  variants = variants.reduce((a, b) =>
    a.flatMap(d =>
      b.map(e => {
        const size = (Object.keys(d)[0] === 'size' ? d.size : e.size) as any
        const color = (Object.keys(d)[0] === 'color' ? d.color : e.color) as any
        const cost = obj.costBy === 'size' ? size.cost : color.cost
        return {
          catalogVariantCode: `${obj.productCode}-${color.code}${size.code}`,
          ...d,
          ...e,
          ...obj,
          cost: cost || obj.cost,
        }
      })
    )
  )

  return variants
}
