import { reactive } from "vue";
import { useServiceCatalogVariant } from '@/services/useServiceCatalogVariant'
import { useResource } from "@/services";
interface Variant {
  _id?: string;
  catalogVariantCode: string;
  colorCode?: string;
  cost?: number;
  description?: string;
  inStock?: boolean;
  sizeCode?: string;
}
export default function useCatalogVariantRepositories() {
  const resource = `/catalog-variants`;
  const variant = reactive<Variant>({
    _id: '',
    catalogVariantCode: '',
    colorCode: '',
    cost: 0,
    description: '',
    inStock: true,
    sizeCode: ''
  })
  const addVariant = async (variant: any) => {
    const { add } = useServiceCatalogVariant(resource, variant)
    try {
      const { status, data } = await add(variant)
      return { status, data }
    } catch (error) {
      console.log(error)
      return {}
    }
  }
  const updateVariant = async (variant: any) => {
    const { patch } = useServiceCatalogVariant(`${resource}/${variant._id}`, variant)
    try {
      const { status, data } = await patch(variant)
      return { status, data }
    } catch (error) {
      console.log(error)
      return {}
    }
  }
  const deleteByIdVariant = async (id: any) => {
    const { deleteOne } = useServiceCatalogVariant(resource, variant)
    try {
      const { status, data } = await deleteOne(id)
      return { status, data }
    } catch (error) {
      console.log(error)
      return {}
    }
  }
  const deleteByIdsVariant = async (ids: string[], catalog: string) => {
    const { deleteByIds } = useServiceCatalogVariant(resource, variant)
    try {
      const { status, data } = await deleteByIds(ids, catalog)
      return { status, data }
    } catch (error) {
      console.log(error)
      return {}
    }
  }
  const findOne = async (id: any) => {
    const { get } = useResource(resource)
    return await get(id)
  }

  const bulkUpdate = async (body: any) => {
    const { patch } = useServiceCatalogVariant(`${resource}/bulk-update`, body)
    return await patch(body)
  }

  return {
    useServiceCatalogVariant,
    variant,
    addVariant,
    updateVariant,
    deleteByIdVariant,
    deleteByIdsVariant,
    findOne,
    bulkUpdate
  }
}
