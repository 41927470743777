
import { Edit, Delete, CloseBold } from '@element-plus/icons'
import UploadImage from '@/components/common/UploadImage.vue'
import { reactive, defineComponent, ref } from 'vue'
import { imageUrl } from '@/utils/image'
export default defineComponent({
  components: { UploadImage, Edit, Delete },
  props: {
    dataSet: {
      type: Array,
    },
  },
  setup(props, { emit }) {
    const downloadFile = (fileName: string, urlData: any) => {
      var aLink = document.createElement('a')
      aLink.download = fileName
      aLink.href = urlData
      var event = new MouseEvent('click')
      aLink.dispatchEvent(event)
    }
    const onDownloadTemplate = async (fileUrl: any) => {
      try {
        const filename = fileUrl?.substring(fileUrl.lastIndexOf('/') + 1)
        downloadFile(filename, fileUrl)
      } catch (e) {
        console.log({ e })
      }
    }
    const columns = [
      {
        title: 'Position',
        dataIndex: 'position',
      },
      {
        title: 'Name',
        dataIndex: 'name',
      },
      {
        title: 'Width',
        dataIndex: 'width',
      },
      {
        title: 'Height',
        dataIndex: 'height',
      },
      {
        title: 'Format',
        dataIndex: 'format',
      },
      {
        title: 'DPI',
        dataIndex: 'dpi',
      },
      {
        title: 'Template',
        dataIndex: 'template',
      },
      {
        title: 'Mockup',
        dataIndex: 'mockupTemplate',
      },
      {
        title: 'Additional Cost',
        dataIndex: 'additional_cost',
      },
    ]
    const dialog = ref(false)
    const options = [
      {
        title: 'Artwork 1',
        value: 'artwork-1',
      },
      {
        title: 'Artwork 2',
        value: 'artwork-2',
      },
      {
        title: 'Artwork 3',
        value: 'artwork-3',
      },
      {
        title: 'Artwork 4',
        value: 'artwork-4',
      },
    ]
    const form = reactive({
      position: '',
      name: '',
      width: 0,
      height: 0,
      template: '',
      additional_cost: 0,
    })
    const callback = (setData: any, files: string[]) => {
      setData.template = files[0]
    }
    const callbackUploadMockupTemplate = (setData: any, files: string[]) => {
      setData.mockupTemplate = files[0]
    }
    const close = () => {
      dialog.value = false
      Object.assign(form, {
        position: '',
        name: '',
        width: '',
        height: '',
        template: '',
        additional_cost: '',
      })
    }
    const status = reactive({
      status: 'add',
      index: -1,
    })
    const editDesign = (value: any, i: number) => {
      Object.assign(form, value)
      dialog.value = true
      status.status = 'edit'
      status.index = i
    }
    const addDesign = () => {
      dialog.value = true
      status.status = 'add'
      status.index = -1
    }
    const dialogConfirm = reactive({
      status: false,
      index: -1,
    })
    const delDesign = (i: number) => {
      Object.assign(dialogConfirm, { status: true, index: i })
    }
    const cancelDelete = () => {
      Object.assign(dialogConfirm, { status: false, index: -1 })
    }
    const confirmDelete = (index: number) => {
      emit('delDesign', index)
      Object.assign(dialogConfirm, { status: false, index: -1 })
    }
    const rules = {
      position: [
        {
          required: true,
          message: 'Please input position',
          trigger: 'change',
        },
      ],
      name: [
        {
          required: true,
          message: 'Please input name',
          trigger: 'blur',
        },
      ],
      height: [
        {
          required: true,
          message: 'Please input height',
          trigger: 'blur',
        },
      ],
      width: [
        {
          required: true,
          message: 'Please input width',
          trigger: 'blur',
        },
      ],
      additional_cost: [
        {
          required: true,
          message: 'Please input additional cost',
          trigger: 'blur',
        },
      ],
    }
    const artwork = ref<any>(null)
    const save = () => {
      artwork.value?.validate((valid: any) => {
        if (valid) {
          const obj = { ...form }
          emit('callback', obj, props.dataSet, status)
          dialog.value = false
          Object.assign(form, {
            position: '',
            name: '',
            width: '',
            height: '',
            template: '',
            additional_cost: '',
          })
        }
      })
    }
    return {
      columns,
      dialog,
      options,
      form,
      callback,
      callbackUploadMockupTemplate,
      close,
      save,
      editDesign,
      status,
      addDesign,
      delDesign,
      dialogConfirm,
      cancelDelete,
      confirmDelete,
      rules,
      artwork,
      onDownloadTemplate,
      imageUrl,
    }
  },
})
